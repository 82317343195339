import { styled } from "styled-components";

import { useEffect, useState } from "react";

import {
  AiOutlineMinus,
  AiOutlinePlus,
  AiOutlineSend,
  AiOutlineSetting,
} from "react-icons/ai";

import { ShimmerThumbnail } from "react-shimmer-effects";
import contentCategories from "../../data/contentCategories";
import DropDownInput from "../../util/components/inputs/DropDownInput";
import FilledButton from "../../util/components/buttons/FilledButton";
import MaterialInput from "../../util/components/MaterialInput";
import CustomToggle from "../../util/components/inputs/CustomToggle";
import useZekToolkit from "../../zekFrontendToolkit/useZekToolkit";
import serverLine from "../../zekFrontendToolkit/network/serverLine";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  overflow: hidden;
  width: 38vw;
  padding: 20px;
  margin-top: 30px;
  border-radius: 10px;
  background: var(--primarySurface);
  box-shadow: 1px 1px 20px 1px #00000024;
  animation: centerScaleReveal 0.3s ease-out forwards;

  @media (max-width: 900px) {
    width: 100%;
    /* gap: 15px; */
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0;
  align-items: center;
  /* align-items: center; */
  width: 100%;
  /* height: 75px; */
  padding: 0 0;
  justify-content: space-between;

  border-top: 1px solid var(--translucent);

  @media (max-width: 900px) {
    display: flex;
    /* height: auto; */
    flex-direction: column;
    gap: 20px;
  }
`;

const Left = styled.div`
  display: flex;
  flex-direction: row;

  gap: 10px;
`;

const Inputs = styled.div`
  padding: p;
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 10px;
`;

let maxTitleLength = 50;
let maxDescriptionLength = 500;

const Warning = styled.div`
  color: tomato;
  width: 100%;
  /* text-align: ; */
  padding-left: 20px;
`;

const Settings = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  padding: 0 0;
`;

const SettingsRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  padding: 0 0;
`;

export default function CreateContent({ contentToUpdate }) {
  const { loggedInUser, popupAlert, isMobile } = useZekToolkit();
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [type, setType] = useState("PUBLIC");
  const [category, setCategory] = useState(null);
  const [description, setDescription] = useState("");
  const [descriptionStatus, setDescriptionStatus] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [minimumKudos, setMinimumKudos] = useState(0);

  //
  const [maxMembersPerRoom, setMaxMembersPerRoom] = useState(5);
  const [maxRoomsAutoAddAuthorTo, setMaxRoomsAutoAddAuthorTo] = useState(10);

  if (contentToUpdate) type = contentToUpdate.type;

  useEffect(() => {
    if (contentToUpdate) {
      setMinimumKudos(contentToUpdate.minimumKudos);
      setType(contentToUpdate.type);

      setTitle(contentToUpdate.title);

      setCategory(contentToUpdate.category);

      if (contentToUpdate.maxMembersPerRoom)
        setMaxMembersPerRoom(contentToUpdate.maxMembersPerRoom);
      if (contentToUpdate.maxRoomsAutoAddAuthorTo)
        setMaxRoomsAutoAddAuthorTo(contentToUpdate.maxRoomsAutoAddAuthorTo);
    }
  }, []);

  console.log("contentToUpdate", contentToUpdate, title);

  if (!loggedInUser)
    return <ShimmerThumbnail height={250} width={"100%"} rounded />;

  function isLoading() {
    if (loading || !loggedInUser) return true;
    return false;
  }

  return (
    <Container>
      <Inputs>
        <MaterialInput
          fontSize={"19px"}
          value={title}
          onChange={(e) => {
            setTitle(e.target.value);
          }}
          multiline={true}
          label={"Title"}
        />

        {descriptionStatus ? (
          <MaterialInput
            rows={3}
            multiline={true}
            label="Description"
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          />
        ) : null}
      </Inputs>

      {getWarning()}

      {getSettingsSection()}

      <Row>
        <Left>
          <FilledButton
            onClick={enableDisableDesc}
            icon={descriptionStatus ? <AiOutlineMinus /> : <AiOutlinePlus />}
          >
            Desc
          </FilledButton>

          <DropDownInput
            label="Category"
            options={contentCategories}
            value={category}
            onChange={setCategory}
          />

          <FilledButton
            icon={<AiOutlineSetting />}
            onClick={enableDisableSettings}
          />
        </Left>

        <FilledButton
          onClick={create}
          icon={<AiOutlineSend />}
          isLoading={isLoading()}
        >
          {isMobile ? "Publish" : null}
        </FilledButton>
      </Row>
    </Container>
  );

  function getSettingsSection() {
    if (!showSettings) return null;

    return (
      <Settings>
        <SettingsRow>
          <MaterialInput
            type="number"
            value={maxMembersPerRoom}
            onChange={(e) => {
              setMaxMembersPerRoom(e.target.value);
            }}
            label={"Maximum Members Per Room"}
          />

          <MaterialInput
            type="number"
            value={maxRoomsAutoAddAuthorTo}
            onChange={(e) => {
              setMaxRoomsAutoAddAuthorTo(e.target.value);
            }}
            label={"Max rooms author can be auto added to"}
          />
        </SettingsRow>

        <SettingsRow>
          <MaterialInput
            type="number"
            value={minimumKudos}
            onChange={(e) => {
              setMinimumKudos(e.target.value);
            }}
            label={"Minimum Kudos"}
          />
        </SettingsRow>

        <SettingsRow>
          <CustomToggle
            toggleStyle={{ padding: 0 }}
            // colorVariation={}
            options={[
              { label: "Public: Anyone can join", value: "PUBLIC" },
              { label: "Private: Requires link to join", value: "PRIVATE" },
            ]}
            value={type}
            onChange={setType}
          />
        </SettingsRow>
      </Settings>
    );
  }

  function getWarning() {
    if (title.length > maxTitleLength)
      return (
        <Warning>
          Please remove {title.length - maxTitleLength} character from the title
        </Warning>
      );

    if (description)
      if (description.length > maxDescriptionLength)
        return (
          <Warning>
            Please remove {description.length - maxDescriptionLength} character
            from the description
          </Warning>
        );
  }

  function enableDisableSettings() {
    setShowSettings(!showSettings);
  }

  function enableDisableDesc() {
    if (descriptionStatus) {
      setDescription("");
    }

    setDescriptionStatus(!descriptionStatus);
  }

  function create() {
    if (isLoading()) return false;

    if (!category) return popupAlert("Please select a category");

    if (parseInt(maxMembersPerRoom) <= 2)
      return popupAlert("Maximum member per room should be greater than 10");

    if (parseInt(maxRoomsAutoAddAuthorTo) <= 2)
      return popupAlert("Author needs to be added to at lest 3 rooms");

    if (!category) return popupAlert("Please select a category");

    if (title.length > 50)
      return popupAlert("Title length should be less than 50 characters");
    if (description) {
      if (description.length > 500)
        return popupAlert(
          "Description length should be less than 500 characters"
        );
    }

    setLoading(true);

    if (contentToUpdate) {
      serverLine
        .patch("/content", {
          contentID: contentToUpdate._id,
          changes: {
            minimumKudos,

            type: type,
            category: category,
            title: title,
            description: { text: description },
            maxRoomsAutoAddAuthorTo,
            maxMembersPerRoom,
          },
        })
        .then((newData) => {
          setLoading(false);
          window.goTo("/p/" + newData.slugID)();
        })
        .catch((e) => {
          setLoading(false);
          window.popupAlert(e.message);
        });
    } else {
      try {
        serverLine
          .post("/content", {
            values: {
              minimumKudos,
              type,
              category: category,
              title: title,
              description: { text: description },
              maxRoomsAutoAddAuthorTo,
              maxMembersPerRoom,
            },
          })
          .then((newData) => {
            setLoading(false);
            window.goTo("/p/" + newData.slugID)();
          })
          .catch((e) => {
            setLoading(false);
            window.popupAlert(e.message);
          });
      } catch (e) {
        popupAlert(e.message);
        setLoading(false);
      }
    }
  }
}
