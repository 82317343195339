import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  transition: 0.25s ease-in-out;
  text-decoration: underline;

  &:hover {
    transform: scale(0.95);
  }
`;

const IconContainer = styled.div``;

const TextContainer = styled.div`
  text-transform: capitalize;
`;

export default function UnderlinedButton({ children, icon, onClick, href }) {
  if (!onClick && href) {
    onClick = (e) => {
      e.preventDefault();
      window.goTo(href)();
      return false;
    };
  }

  return (
    <Container onClick={onClick}>
      {icon && <IconContainer>{icon}</IconContainer>}

      {children && <TextContainer>{children}</TextContainer>}
    </Container>
  );
}
