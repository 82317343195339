import getUrlQuery from "../router/controllers/getUrlQuery";
import urlQueryObjToUrl from "../router/controllers/urlQueryObjToUrl";

export default function generateshowPopupFunc(setDynamicPopupData) {
  function showPopupComponent(data) {
    let queryObj = getUrlQuery();

    if (queryObj.formPage) {
      if (data) {
        setDynamicPopupData(data);
      } else {
        return window.history.back();
      }
    } else {
      queryObj.formPage = "true";

      let path = window.location.pathname;
      let thePath = removeLastSlash(path) + urlQueryObjToUrl(queryObj);

      window.history.pushState({}, "", thePath);

      setDynamicPopupData(data);
    }
  }

  return showPopupComponent;
}

function removeLastSlash(urlString) {
  if (urlString[urlString.length - 1] == "/") {
    return urlString.slice(0, urlString.length - 1);
  } else {
    return urlString;
  }
}
