export default function updateRouteState(
  newRoute,
  { setCurrentRoute, setNonPopupRoute },
) {
  setCurrentRoute(newRoute);

  let isNotPopup = newRoute.indexOf('popup=') == -1;

  if (isNotPopup) {
    setNonPopupRoute(newRoute);
  }
}
