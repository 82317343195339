import serverLine from "../../../../zekFrontendToolkit/network/serverLine";

export default async function getChatGroup({
  contentID,
  userID,
  chatGroupType,
}) {
  let path = `/chat-group/?userID=${userID}&chatGroupType=${chatGroupType}`;

  if (contentID) path += `&contentID=${contentID}`;

  let data = await serverLine.get(path);

  return data; //chatGroup & New join
}
