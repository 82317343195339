import getProfileLink from "./getProfileLink";

export default function goToProfile(username, suffix = "", openInNewPage) {
  if (window.innerWidth < 900) openInNewPage = false;
  username = username.toLowerCase();

  return window.goTo(getProfileLink(username, suffix), {
    // isAbsolute: true,
    openInSamePage: openInNewPage ? false : true,
  });
}
