import styled from "styled-components";
import ChatGroupBox from "./ChatGroupBox";

import LoadingSection from "../../../../util/components/LoadingSection";
import { useEffect, useState } from "react";
import loadChatGroups from "../../controllers/api/loadChatGroups";
import CustomToggle from "../../../../util/components/inputs/CustomToggle";

const Info = styled.div`
  width: 100%;
  padding: 20px;
`;

const LeftPanel = styled.div`
  height: 100%;
  width: 100%;
  padding: 0;
  padding-top: 0;
  overflow: visible;
  overflow-y: auto;
  border-right: 1px solid var(--translucentHard);
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (max-width: 900px) {
    border: none;
    ${({ $selectedChatGroupID }) => {
      if ($selectedChatGroupID) return `display:none;`;
    }}
  }
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Top = styled.div``;

let typeOptions = [
  { value: "GENERAL", label: "General" },
  { value: "MUTED", label: "Muted" },
];

export default function ChatGroupsPanel() {
  let {
    chatGroupsType,
    setChatGroupsType,
    mutedChatGroups,
    chatGroupsAreLoading,
    chatGroups,
    selectedChatGroupID,
    friendsLastSeenAt,
  } = window.globalChatSpace;

  useEffect(() => {
    loadChatGroups();
  }, [chatGroupsType]);

  let topPart = (
    <Top>
      <CustomToggle
        toggleStyle={{ padding: 0, gap: "10px" }}
        btnStyle={{ flex: 1, borderRadius: "10px" }}
        colorVariation={"SURFACE"}
        options={typeOptions}
        value={chatGroupsType}
        onChange={setChatGroupsType}
      />
    </Top>
  );

  if (chatGroupsAreLoading)
    return (
      <LeftPanel $selectedChatGroupID={selectedChatGroupID}>
        {topPart}
        <LoadingSection />
      </LeftPanel>
    );

  let theList = chatGroups;

  if (chatGroupsType == "MUTED") theList = mutedChatGroups;

  let groupBoxes = theList.map((item, index) => (
    <ChatGroupBox
      key={item._id}
      highlight={selectedChatGroupID == item._id}
      friendsLastSeenAt={friendsLastSeenAt}
      item={item}
      onClick={selectNewGroup}
    />
  ));

  return (
    <LeftPanel $selectedChatGroupID={selectedChatGroupID}>
      {topPart}
      <List>
        {groupBoxes.length ? (
          groupBoxes
        ) : (
          <Info> New Chats Groups Will Appear Here</Info>
        )}
      </List>
    </LeftPanel>
  );

  function selectNewGroup(chatGroup) {
    let gotoOptions = null;
    if (selectedChatGroupID) {
      gotoOptions = { isReplace: true };
    }

    window.goTo("/chat/" + chatGroup._id, gotoOptions)();
  }
}
