import { useEffect } from "react";
import updateColorMode from "./updateColorMode";
import universalLocalStorage from "../universalLocalStorage";

export default function ColorsLoader({
  lightTheme,
  darkTheme,
  setColorMode,
  defaultColorMode,
  namespace,
  themeOverride,
}) {
  useEffect(() => {
    if (themeOverride) {
      lightTheme = { ...lightTheme, ...themeOverride };
      darkTheme = { ...darkTheme, ...themeOverride };
    }

    universalLocalStorage
      .getItem(namespace + "-colorMode")
      .then((colorMode) => {
        if (defaultColorMode) {
          if (!colorMode) colorMode = defaultColorMode;
        }

        updateColorMode({
          newColorMode: colorMode,
          lightTheme,
          darkTheme,
          setColorMode,
          namespace,
        });
      });
  }, [themeOverride]);

  return null;
}
