import { useContext, useState } from "react";
import styled from "styled-components";
import { MoonLoader } from "react-spinners";
import { AiOutlineSend } from "react-icons/ai";
import sendDirectChatWithoutOpening from "../controllers/api/sendDirectChatWithoutOpening";
import Context from "../../../Context";
import useZekToolkit from "../../../zekFrontendToolkit/useZekToolkit";

const MessageSpaceContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0;
  height: 65px;
  padding-top: 0;
  gap: 10px;
`;

const MessageSpace = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  height: 100%;
  width: 100%;
  overflow: hidden;
  border: 1px solid var(--translucentHard);
  /* background-color: var(--translucentLight); */
  border-radius: 15px;
`;

const WriteMessage = styled.input`
  height: 100%;
  resize: none;
  width: 100%;
  border: none;

  padding: 0;
  padding-left: 20px;
  border-radius: 10px;
  color: var(--color);
  background-color: var(--secondarySurface);
  font-size: 16px;
`;

const PostButton = styled.button`
  height: 100%;
  border: none;
  background-color: var(--secondarySurface);
  display: flex;
  flex-direction: row;
  padding: 0 25px;
  gap: 10px;
  justify-content: center;
  border-radius: 10px;
  font-size: 18px;
  align-items: center;
  cursor: pointer;
  color: var(--color);

  &:hover {
    background-color: var(--primarySurface);
  }
`;

const PostButtonIcon = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

export default function DirectMessageBox({ user }) {
  const { loggedInUserID } = useZekToolkit();
  const [message, setMessage] = useState("");
  const [sending, setSending] = useState(false);

  if (loggedInUserID == user._id) return null;

  return (
    <MessageSpaceContainer>
      <MessageSpace>
        <WriteMessage
          placeholder={"Type Message Here"}
          onChange={updateMessage}
          value={message}
        />

        <PostButton onClick={doSend}>
          <PostButtonIcon>
            {sending ? <MoonLoader size={20} /> : <AiOutlineSend />}
          </PostButtonIcon>
        </PostButton>
      </MessageSpace>
    </MessageSpaceContainer>
  );

  function updateMessage(e) {
    setMessage(e.target.value);
  }

  async function doSend() {
    if (!loggedInUserID) return window.popupAlert("Login Required");

    try {
      setSending(true);
      await sendDirectChatWithoutOpening({ user, textMessage: message });

      window.popupAlert("Message Sent");
      setSending(false);
      setMessage("");
    } catch (e) {
      window.popupAlert(e.message);
    }
  }
}
