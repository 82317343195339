import styled from "styled-components";

import useZekToolkit from "../zekFrontendToolkit/useZekToolkit.js";
import Header from "../util/components/core/Header.js";
import Boilerplate from "./Boilerplate.js";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  height: 100vh;
  height: 100svh;
  padding: 0;
  width: 100vw;
  overflow-y: scroll;

  background-size: cover;

  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
    overflow: unset;
    gap: 10px;
    overflow-y: scroll;
    border-radius: 0 0 10px 10px;
    border: 1px solid var(--foreground);
    flex: unset;
    box-shadow: rgb(54 143 198) 0px 1px 25px 1px;
    padding: 0;
    padding-top: 0;
  }
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;

  /* width: var(--headerWidth); */
  height: 100vh;
  height: 100svh;
  flex: 1;
  padding-top: var(--mainPadding);
  padding-right: var(--mainPadding);

  gap: 50px;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 900px) {
    width: 100%;
    overflow: unset;
    height: 100%;
    padding: 0;
    padding-top: 0;
    padding: 20px;
  }
`;

export default function RouteEncapsulation({ children }) {
  const { loggedInUserID } = useZekToolkit();

  if (!loggedInUserID) return <Boilerplate>{children}</Boilerplate>;

  return (
    <Boilerplate>
      <Container>
        {loggedInUserID ? <Header /> : null}
        <Main>{children}</Main>
      </Container>
    </Boilerplate>
  );
}
