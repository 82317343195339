import styled from "styled-components";
import { useEffect } from "react";

import LoadingSection from "../../util/components/LoadingSection.js";
import BrandContainer from "../../util/components/brand/BrandContainer.js";

import getUrlParams from "../../util/controllers/getUrlParams.js";
import sendServerAuthCode from "../../util/controllers/loginWithGoogle/sendServerAuthCode.js";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  /* margin-top: 100px; */
  justify-content: center;
  height: 100%;
  align-items: center;
  width: 100vw;
`;

const ErrorBox = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 500px;
`;

const Logo = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
  align-items: center;

  border-radius: 15px;

  background: var(--primarySurface);
  border: 1px solid var(--foreground);
`;

function SendAuthCode() {
  const searchParams = getUrlParams();

  useEffect(() => {
    // console.log("req sending auth code");
    sendCode();
  }, []);

  return (
    <Container>
      <Logo>
        <BrandContainer />
      </Logo>

      <LoadingSection />
    </Container>
  );

  function sendCode() {
    console.log("sending auth code");
    let location = window.location.origin + window.location.pathname;
    let code = searchParams.get("code");

    sendServerAuthCode({ code, location });
  }
}

export default SendAuthCode;
