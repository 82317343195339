import { useState } from "react";
import styled from "styled-components";
import UnderlinedButton from "../buttons/UnderlinedButton";
import LikeDislikeButtons from "../voteButtons/LikeDislikeButtons";
import FilledButton from "../buttons/FilledButton";
import { BsThreeDotsVertical } from "react-icons/bs";
import Options from "../inputs/Options";

import ReportsDataBox from "../ReportsDataBox";
import useZekToolkit from "../../../zekFrontendToolkit/useZekToolkit";
import serverLine from "../../../zekFrontendToolkit/network/serverLine";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  padding: 0;
  background: var(--secondaryInput);
  border-radius: var(--borderRadius);
  padding: 20px 30px;
  animation: centerScaleReveal 0.3s ease-out forwards;
  /* border: 1px solid var(--primaryInput); */
  /* box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.05); */

  ${({ colorVariation }) => {
    if (colorVariation == "SURFACE")
      return `
        background: var(--primarySurface);
    `;
  }}

  @media (max-width: 900px) {
    padding: 20px;
  }
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;

  gap: 10px;
`;

const PrimaryLine = styled.div`
  font-size: 21px;
  font-weight: 900;
`;

const SecondaryLine = styled.div`
  font-size: 13px;
  display: flex;
  gap: 10px;
  flex-direction: row;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0;
  padding-top: 0;
  gap: 10px;

  @media (max-width: 900px) {
    gap: 5px;
  }
`;

const ContentData = styled.span`
  text-transform: capitalize;
  font-weight: 600;
`;

const Info = styled.span`
  text-transform: capitalize;
  font-weight: 600;
`;

export default function FeedItemConclusion({
  item,
  colorVariation,
  showContentData,
  style,
  showReportsData,
  ignoreBans,
}) {
  const { popupAlert, loggedInUserID, showPopup, goTo } = useZekToolkit();

  const [isDeleted, setIsDeleted] = useState(null);

  item = JSON.parse(JSON.stringify(item));
  item = { ...item };

  if (isDeleted || item.isArchived)
    return <Container colorVariation={colorVariation}>Item Deleted</Container>;

  let text = "";

  if (item.data) {
    if (item.data.text) {
      text = item.data.text;
    }
  }

  if (item.isBanned && !ignoreBans)
    return (
      <Container style={style} colorVariation={colorVariation}>
        Item has been banned
      </Container>
    );

  return (
    <Container style={style} colorVariation={colorVariation}>
      <Main>
        {showContentData ? (
          <ContentData>{item.contentTitle}</ContentData>
        ) : null}
        <PrimaryLine>{text}</PrimaryLine>
        <SecondaryLine>
          <UnderlinedButton href={`/${item.author.username}`}>
            {item.author.username}
          </UnderlinedButton>

          <Info>Room: #{item.roomNumber}</Info>
        </SecondaryLine>
      </Main>

      <Buttons>
        <LikeDislikeButtons
          likeStatus={item.likeStatus}
          likeCount={item.likeCount}
          dislikeCount={item.dislikeCount}
          conclusionID={item._id}
          authorUserID={item.authorUserID}
          colorVariation={colorVariation == "SURFACE" ? "ACCENT" : "SURFACE"}
        />

        <FilledButton
          mobileStyle={{ width: "20px" }}
          onClick={showContentMenu}
          icon={<BsThreeDotsVertical />}
          colorVariation={colorVariation == "SURFACE" ? "ACCENT" : "SURFACE"}
        />
      </Buttons>

      {showReportsData ? (
        <ReportsDataBox item={item} subjectType={"CONCLUSION"} />
      ) : null}
    </Container>
  );

  function showContentMenu() {
    let options = [];

    if (loggedInUserID == item.authorUserID) {
      options = [
        {
          label: "Edit",
          onClick: goTo(`/edit-conclusion/${item._id}`),
        },
        { label: "Delete", onClick: deleteContent },
      ];
    } else {
      options = [
        {
          label: "Report",
          onClick: goTo(
            `/create-report/?subjectType=conclusion&subjectID=${item._id}`
          ),
        },
      ];
    }

    showPopup({ title: "options", component: <Options options={options} /> });
  }

  function deleteContent() {
    let options = [
      { label: "Yes! Delete Post", onClick: doIt },
      {
        label: "No",
        onClick: () => {
          goTo(-1);
        },
      },
    ];

    showPopup({ title: "options", component: <Options options={options} /> });

    function doIt() {
      popupAlert("Post Deleted!");
      setIsDeleted(true);
      goTo(-1);
      serverLine.delete(`/conclusion/?conclusionID=${item._id}`);
    }
  }
}
