import styled from "styled-components";

import { FadeLoader } from "react-spinners";

const Main = styled.div`
  width: 100%;
  height: 100px;
  margin-top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function LoadingSpinner() {
  return (
    <Main>
      <FadeLoader
        color="var(--translucent)"
        height={5}
        width={10}
        speedMultiplier={1.5}
      />

      {/* <MoonLoader color="var(--accentColor)" size={30} /> */}
    </Main>
  );
  // return (
  //   <Main>
  //     <BarLoader />
  //   </Main>
  // );
}
