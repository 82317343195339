import { styled } from "styled-components";
import { useState } from "react";

import { PiSmileySadThin } from "react-icons/pi";

import CustomButton from "../../../util/components/inputs/CustomButton";
import LoadingSection from "../../../util/components/LoadingSection";
import BrandContainer from "../../../util/components/brand/BrandContainer";
import useZekToolkit from "../../../zekFrontendToolkit/useZekToolkit";
import fullLogout from "../../../util/controllers/fullLogout";

const Success = styled.div`
  /* margin-top: 100px; */
  width: 100vw;
  height: 100vh;
  padding: 50px 20px;
  border-radius: 10px;
  background: var(--primarySurface);
  border: 1px solid var(--foreground);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  @media (max-width: 900px) {
    width: 90vw;
  }
`;
const SuccessIcon = styled.div`
  background-color: var(--translucent);
  height: 100px;
  width: 100px;
  display: flex;

  justify-content: center;
  align-items: center;
  font-size: 30px;
  border-radius: 100px;
`;
const SuccessText = styled.h2``;
const SuccessButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

export default function YouAreBanned() {
  const [loading, setLoading] = useState(false);
  if (loading) return <LoadingSection />;
  return (
    <Success>
      <BrandContainer />

      <SuccessIcon>
        <PiSmileySadThin />
      </SuccessIcon>

      <SuccessText>Sorry! You have been banned</SuccessText>

      <SuccessButtons>
        <CustomButton variant="outlined" onClick={doLogout}>
          Logout
        </CustomButton>
      </SuccessButtons>
    </Success>
  );

  function doLogout() {
    setLoading(true);
    fullLogout();
  }
}
