import universalLocalStorage from '../universalLocalStorage';
import applyColors from './applyColors';

export default async function updateColorMode({
  newColorMode,
  namespace,
  setColorMode,
  lightTheme,
  darkTheme,
}) {
  setColorMode(newColorMode);
  await universalLocalStorage.setItem(namespace + '-colorMode', newColorMode);
  applyColors({ colorMode: newColorMode, lightTheme, darkTheme });
}
