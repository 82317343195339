import styled from "styled-components";
import { useEffect, useState } from "react";
import { BsInstagram, BsThreeDotsVertical, BsTwitterX } from "react-icons/bs";
import DirectMessageBox from "../chatPage/components/DirectMessageBox.js";
import capitalizeFirstLetter from "../../util/controllers/capitalizeFirstLetter.js";
import calculateAge from "../../util/controllers/calculateAge.js";
import BlockUnblockInterface from "./BlockUnblockInterface.js";

import limitStringLength from "../../util/controllers/limitStringLength.js";
import getImageURL from "../../util/controllers/getImageURL.js";
import PageNotFound from "../../util/components/PageNotFound.js";
import LoadingSection from "../../util/components/LoadingSection.js";
import getLocationString from "../../util/controllers/getLocationString.js";
import SocialLink from "./SocialLink.js";
import Options from "../../util/components/inputs/Options.js";

import ProfileFeed from "./ProfileFeed.js";
import FollowButton from "../../util/components/FollowButton.js";

import FilledButton from "../../util/components/buttons/FilledButton.js";
import useZekToolkit from "../../zekFrontendToolkit/useZekToolkit.js";
import serverLine from "../../zekFrontendToolkit/network/serverLine.js";

const MainContent = styled.div`
  padding: 50px 130px;
  /* padding-top: 150px; */
  display: flex;
  flex-direction: column;
  gap: 100px;
  align-items: center;

  @media (max-width: 900px) {
    padding: 5vw;
  }
`;

const Top = styled.div`
  width: 45vw;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const HeroSection = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;

  flex-direction: row;
  /* justify-content: space-between; */
  margin-bottom: 0;
  gap: 25px;
  border-radius: 10px;
  background: var(--primarySurface);
  padding: 20px;
  /* box-shadow: var(--lightShadow); */
  animation: centerScaleReveal 0.3s ease-out forwards;

  width: 100%;

  @media (max-width: 900px) {
    flex-direction: column;
    margin-top: 0;
    gap: 20px;
    /* background-color: var(--secondarySurface); */
    width: calc(100vw - 45px);

    box-shadow: unset;
    border: none;
    padding: 50px 25px;
  }
`;

const HeroLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 10px;
  justify-content: space-between;

  height: 100%;
  flex: 1;

  @media (max-width: 900px) {
    gap: 20px;
  }
`;

const TitleText = styled.div`
  font-size: 28px;
  font-weight: 900;

  @media (max-width: 900px) {
    font-size: 18px;
  }
`;

const HeroTitle = styled.div`
  font-weight: 800;
  /* margin-bottom: 10px; */

  padding-top: 10px;

  /* border-bottom: 1px solid var(--translucent); */

  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  border-radius: 10px;

  @media (max-width: 900px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const HeroDescription = styled.div`
  font-size: 18px;
  /* margin-top: -15px; */
  /* width: 600px; */
  opacity: 0.7;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  padding: 0;
  /* line-height: 30px; */
  width: 100%;

  border-radius: 10px;
  gap: 10px;

  @media (max-width: 900px) {
    text-align: center;
    font-size: 18px;
    width: 100%;
    gap: 20px;
  }
`;

const HeroButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;

  @media (max-width: 900px) {
    justify-content: center;
  }
`;

const BigImage = styled.img`
  height: auto;
  width: 100%;
  object-fit: cover;
  border-radius: 0;
  cursor: pointer;
  border-radius: 15px;
`;

const ProfileImage = styled.img`
  height: 170px;
  width: 170px;
  object-fit: cover;
  border-radius: 0;
  cursor: pointer;
  border-radius: 15px;
  /* box-shadow: var(--hardShadow); */

  @media (max-width: 900px) {
    height: 150px;
    width: 150px;
    border-radius: 100px;
  }
`;

const DescLine = styled.div`
  font-size: 18px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media (max-width: 900px) {
    font-size: 13px;
  }
`;

const Spacers = styled.span`
  width: 10px;
  display: flex;
`;

export default function ProfilePage({ pathSegments }) {
  let { loggedInUserID, refreshUser, popupAlert, showPopup } = useZekToolkit();
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  let username = pathSegments[0];

  useEffect(() => {
    if (userData) {
      if (userData.user) {
        if (userData.user.name) {
          document.title = userData.user.name;
        }
      }
    }
  }, [username, userData]);

  useEffect(() => {
    refreshUser(); // To update the following status
    doLoading();
  }, [username]);

  if (error == "User not found")
    return <PageNotFound message="Page not found" />;

  if (error) return JSON.stringify(error);

  if (!userData || loading) return <LoadingSection />;

  if (!userData.user.bio) userData.user.bio = "Hi I am new to cottonchat";

  let locationStr = getLocationString(userData.user);

  let heroSectionInfo = (
    <HeroLeft>
      <HeroTitle>
        <TitleText>{userData.user.name}</TitleText>

        <HeroButtons>
          <SocialLink
            baseLink="https://instagram.com"
            icon={<BsInstagram />}
            field="instagram"
            data={userData.user.socialLinks}
          />

          <SocialLink
            baseLink="https://twitter.com"
            data={userData.user.socialLinks}
            icon={<BsTwitterX />}
            field="twitter"
          />

          {getButtons()}
        </HeroButtons>
      </HeroTitle>

      <HeroDescription>
        <DescLine> {limitStringLength(userData.user.bio, 100)} </DescLine>
        <DescLine>
          @{userData.user.username}
          <Spacers /> . <Spacers />
          {userData.user.kudos} Kudos <Spacers />.<Spacers />
          {locationStr ? "  " + locationStr : "Earth"}
          <Spacers /> .<Spacers />
          {calculateAge(userData.user.dateOfBirth)}
          <Spacers />
          {capitalizeFirstLetter(userData.user.gender.toLowerCase())}
        </DescLine>
      </HeroDescription>
    </HeroLeft>
  );

  let heroSection = (
    <HeroSection>
      <ProfileImage
        onClick={OpenImage}
        src={getImageURL(userData.user.profileImage, true)}
      />

      {heroSectionInfo}
    </HeroSection>
  );

  function OpenImage() {
    showPopup({
      title: "Profile Image",
      component: (
        <BigImage src={getImageURL(userData.user.profileImage, true)} />
      ),
    });
  }

  function getButtons() {
    if (loggedInUserID !== userData.user._id)
      return (
        <>
          <FollowButton userID={userData.user._id} />
          <FilledButton
            style={{ height: "100%", width: "40px" }}
            variant="minimal"
            onClick={showOptions}
            icon={<BsThreeDotsVertical />}
          />
        </>
      );

    return null;
  }

  return (
    <MainContent id="heroSection">
      <Top>
        {heroSection}

        <DirectMessageBox user={userData.user} />
      </Top>
      <ProfileFeed userData={userData.user} />
      <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br />
    </MainContent>
  );

  async function doLoading() {
    setUserData(null);
    setLoading(true);

    try {
      let data = await serverLine.get("/profile/?username=" + username);

      setUserData(data);
    } catch (e) {
      setError(e.message);
    }

    setLoading(false);
  }

  function showOptions() {
    let options = [];

    if (!loggedInUserID) return popupAlert("Login is required");

    options = [
      {
        label: "Report User",
        onClick: window.goTo(
          `/create-report/?subjectType=profile&subjectID=${userData.user._id}`
        ),
      },
      { label: "Manage Blocking", onClick: manageBlocking },
    ];

    showPopup({ title: "options", component: <Options options={options} /> });
  }

  function manageBlocking() {
    showPopup({
      title: "Manage Blocking",
      component: (
        <BlockUnblockInterface
          receiverUserData={userData.user}
          receiverUserID={userData.user._id}
          senderUserID={loggedInUserID}
        />
      ),
    });
  }
}
