async function fullLogout() {
  window.zekToolkitFeatures.logout();

  let { updatePushNotificationDecision } = window.sharedState;

  if (window.chatSocket) {
    window.chatSocket.disconnect();
  }

  updatePushNotificationDecision(null);
}

export default fullLogout;
