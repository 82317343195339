import { GoogleOAuthProvider } from "@react-oauth/google";
import ZekToolkitProvider from "./ZekToolkitProvider";

export default function ZekToolkit({
  googleAuthClientID,
  routes,
  localServerURL,
  productionServerURL,
  forceProductionMode,
  muiTheme,
  namespace,
  lightTheme,
  darkTheme,
  defaultColorMode,

  RouteEncapsulation,
}) {
  return (
    <GoogleOAuthProvider clientId={googleAuthClientID}>
      <ZekToolkitProvider
        googleAuthClientID={googleAuthClientID}
        routes={routes}
        localServerURL={localServerURL}
        productionServerURL={productionServerURL}
        forceProductionMode={forceProductionMode}
        muiTheme={muiTheme}
        namespace={namespace}
        lightTheme={lightTheme}
        darkTheme={darkTheme}
        defaultColorMode={defaultColorMode}
        RouteEncapsulation={RouteEncapsulation}
      />
    </GoogleOAuthProvider>
  );
}
