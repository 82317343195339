export default function applyColors({ lightTheme, darkTheme, colorMode }) {
  let colors = darkTheme;

  if (colorMode == 'LIGHT') colors = lightTheme;

  for (let key in colors) {
    let val = colors[key];
    document.documentElement.style.setProperty(`--${key}`, val);
  }
}
