import { styled } from "styled-components";
import BasicInfoEditor from "../../util/components/core/BasicInfoEditor";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

  padding: 50px 0;
  gap: 50px;
  height: 100%;
  overflow-y: scroll;
  padding-bottom: 50px;

  @media (max-width: 900px) {
    padding: 30px 0;
  }
`;

const Main = styled.div`
  width: 38vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  background-color: var(--primarySurface);
  border-radius: 10px;
  padding: 25px;

  @media (max-width: 900px) {
    width: 100%;
    /* padding: 25px; */
  }
`;

export default function ProfileEditor() {
  return (
    <Container>
      <Main>
        <BasicInfoEditor />
      </Main>
    </Container>
  );
}
