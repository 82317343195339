export default function urlQueryObjToUrl(queryInput) {
  let newQueryUrl = '/?';
  let i = 0;
  let queryLen = Object.keys(queryInput).length;
  for (let key in queryInput) {
    if (queryInput[key]) {
      if (i == queryLen - 1) {
        newQueryUrl += `${key}=${queryInput[key]}`;
      } else {
        newQueryUrl += `${key}=${queryInput[key]}&`;
        i++;
      }
    }
  }

  return newQueryUrl;
}
