import { styled } from "styled-components";
import { AiOutlineSend } from "react-icons/ai";
import { useEffect, useState } from "react";

import LoadingSection from "../../../../util/components/LoadingSection";

import MaterialInput from "../../../../util/components/MaterialInput";

import FilledButton from "../../../../util/components/buttons/FilledButton";

import FeedItemConclusion from "../../../../util/components/feedItem/FeedItemConclusion";
import serverLine from "../../../../zekFrontendToolkit/network/serverLine";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Cards = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  overflow-y: scroll;
  flex-direction: column;
  gap: 25px;
  padding: 20px 20px;
`;
const BottomPart = styled.div`
  /* height: 100px; */
  width: 100%;
  padding: 10px;
  align-items: center;
  gap: 10px;

  justify-content: space-between;
  padding: 15px 15px;
  display: flex;
  flex-direction: row;
  border-top: 1px solid var(--translucentHard);

  @media (max-width: 900px) {
    padding: 20px 20px;
    border: none;
  }
`;

const Notice = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  font-size: 27px;
  opacity: 0.7;
  font-size: 900;
  justify-content: center;
  align-items: center;
`;

export default function ConclusionSection() {
  const { selectedChatGroupID, selectedChatGroupData } = window.globalChatSpace;
  const [text, setText] = useState("");

  const [loading, setLoading] = useState(false);

  const [conclusions, setConclusions] = useState([]);

  useEffect(() => {
    loadConclusions();
  }, [selectedChatGroupID]);

  if (!selectedChatGroupData) return;
  if (!selectedChatGroupData.group) return;

  let bottomBtnStyle = { height: "56px", width: "56px" };

  let theBottomPart = (
    <>
      <MaterialInput
        onEnter={postConclusions}
        label={"Type your conclusion Here"}
        onChange={updateText}
        value={text}
        maxRows={5}
        multiline={true}
      />

      <FilledButton
        style={bottomBtnStyle}
        variant="filled"
        onClick={postConclusions}
        icon={<AiOutlineSend />}
      />
    </>
  );

  if (loading)
    return (
      <Container>
        <LoadingSection />
      </Container>
    );

  return (
    <Container>
      <Cards id="chat-panel">
        {conclusions.map((item) => (
          <FeedItemConclusion key={item._id} item={item} />
        ))}
      </Cards>

      <BottomPart>{theBottomPart}</BottomPart>
    </Container>
  );

  async function loadConclusions() {
    setLoading(true);
    let data = await serverLine.get(
      `/conclusions/?contentID=${selectedChatGroupData.group.contentID}`
    );
    setConclusions(data);
    setLoading(false);
  }

  function updateText(e) {
    setText(e.target.value);
  }

  async function postConclusions() {
    if (!text) return window.doAlert("text can't be empty");
    if (!text.trim()) return window.doAlert("text can't be empty");

    try {
      setLoading(true);
      await serverLine.post("/conclusion", {
        chatGroupID: selectedChatGroupID,
        data: { text },
      });

      await loadConclusions();
      setText("");
      setLoading(false);
    } catch (e) {
      console.warn(e);
      setLoading(false);
      window.popupAlert(e.message);
    }
  }
}
