import sendGoogleAccessToken from './sendGoogleAccessToken';

export default function handleGoogleAuthRes({
  setLoading,
  namespace,

  refreshUser,
}) {
  return async res => {
    setLoading(true);
    console.log(res);
    await sendGoogleAccessToken({
      accessToken: res.access_token,
      namespace,

      refreshUser,
    });
    setLoading(false);
  };
}
