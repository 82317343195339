import { useEffect, useState } from "react";
import FeedSection from "./FeedSection";
import FeedItemDiscussion from "../../../../util/components/feedItem/FeedItemDiscussion";
import InfiniteScroll from "../../../../util/components/InfiniteScroll";
import CustomMasonry from "../../../../util/components/CustomMasonry";

import CustomButton from "../../../../util/components/inputs/CustomButton";
import FeedItemConclusion from "../../../../util/components/feedItem/FeedItemConclusion";
import serverLine from "../../../../zekFrontendToolkit/network/serverLine";

export default function ContentFeed({
  categoryType,
  type,
  title,
  mode = "COMPACT",
  scrollData,
  maxCol = 2,
  disabledInfiniteScroll,
  loadSecondIndexAsWell,
  forContentPage,
}) {
  const [data, setData] = useState({});
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(true);
  const [reachedTheEnd, setReachedTheEnd] = useState(false);

  useEffect(() => {
    setOffset(0);
    setData({});
    doLoading({
      freshLoad: true,
      newCategoryType: categoryType,
    });
  }, [categoryType, type]);

  let list = data[type];
  if (!list) list = [];

  let content = null;

  let Comp = FeedItemDiscussion;

  if (type == "CONCLUSION") Comp = FeedItemConclusion;

  if (!loading) {
    let items = list.map((item) => (
      <Comp
        colorVariation="SURFACE"
        forContentPage={forContentPage}
        key={item._id}
        item={item}
      />
    ));

    let colsOnMobile = 1;

    content = (
      <CustomMasonry colsOnMobile={colsOnMobile} maxCol={maxCol} list={items} />
    );

    if (!list.length) return null;

    if (!list.length)
      content = (
        <CustomButton variant="minimal" disabled={true}>
          Nothing Found
        </CustomButton>
      );
  }

  let core = <>{content}</>;

  let toRender = core;

  if (!disabledInfiniteScroll)
    toRender = (
      <InfiniteScroll
        loading={loading}
        load={doLoading}
        scrollData={scrollData}
        reachedTheEnd={reachedTheEnd}
      >
        {core}
      </InfiniteScroll>
    );

  return <FeedSection title={title}>{toRender}</FeedSection>;

  async function loadData({ offset, type, categoryType }) {
    let loadedData = await serverLine.get(
      `/feed/?type=${type.toLowerCase()}&category=${categoryType}&offset=${offset}`
    );

    return loadedData;
  }

  async function doLoading({ freshLoad, newCategoryType }) {
    setLoading(true);

    if (!newCategoryType) newCategoryType = categoryType;

    let theOffset = offset;

    if (freshLoad) theOffset = 0;

    let loadedData = await loadData({
      offset: theOffset,
      type,
      categoryType: newCategoryType,
    });

    let newOffset = theOffset + 1;
    let newList = [];

    if (!freshLoad) newList = [...list];

    if (!loadedData.length) {
      if (theOffset !== 0) setReachedTheEnd(true);
    } else {
      setOffset(newOffset);
      newList = [...loadedData];
      setData({ [type]: newList });
    }

    setLoading(false);

    if (loadSecondIndexAsWell) {
      if (newOffset == 1) {
        let newData = await loadData({
          offset: newOffset,
          categoryType: newCategoryType,
        });
        newList = [...newList, ...newData];
        setData({ [type]: newList });
        setOffset(newOffset + 1);
      }
    }
  }
}
