import { styled } from "styled-components";
import { useEffect, useState } from "react";

import selectFile from "../../controllers/selectFile.js";
import compressAndUploadFile from "../../controllers/compressAndUploadFile.js";

import Context from "../../../Context.js";

import { AiOutlineCheck, AiOutlineUpload } from "react-icons/ai";

import ImageCropper2 from "../ImageCropper2.js";

import DropDownLocationInput from "../DropDownLocationInput.js";
import CustomDateInput from "../inputs/CustomDateInput.js";
import LoadingSection from "../LoadingSection.js";
import MaterialInput from "../MaterialInput.js";
import ShowSelectedImage from "../ShowSelectedImage.js";

import CustomToggle from "../inputs/CustomToggle.js";
import ProfilePicture from "../ProfilePicture.js";
import FilledButton from "../buttons/FilledButton.js";
import SocialLinksEditor from "../profile/SocialLinksEditor.js";
import useZekToolkit from "../../../zekFrontendToolkit/useZekToolkit.js";
import serverLine from "../../../zekFrontendToolkit/network/serverLine.js";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0;
  width: 100%;

  gap: 10px;
  align-items: center;
`;

const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  gap: 20px;
  justify-content: center;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const Button = styled.div`
  width: 100%;
  font-size: 18px;
  border: 1px solid var(--translucent);
  background: var(--translucent);
  border-radius: 15px;
  color: var(--color);
  text-align: center;
  cursor: pointer;
  padding: 20px 15px;
`;

const ImageUploadSection = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 25px;
  /* width: 100%; */
  justify-content: center;
  align-items: center;
`;

const ImageUploadButton = styled(Button)`
  /* width: 300px; */
  position: absolute;
  right: -12px;
  bottom: -1px;
  width: 50px;
  /* background: var(--primarySurface); */
  padding: 0;
  display: flex;
  border: 1px solid var(--accentLightColor);
  /* backdrop-filter: blur(30px); */

  background: var(--primaryInput);

  justify-content: center;
  align-items: center;
  border-radius: 100px;
  height: 50px;
`;

export default function BasicInfoEditor() {
  const { loggedInUser, refreshUser, popupAlert, showPopup, goTo } =
    useZekToolkit(Context);

  const [config, setConfig] = useState({});
  const [selectedImages, setSelectedImages] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (loggedInUser) {
      let newState = { ...config };
      newState = { ...loggedInUser.me };
      setConfig(newState);
    }
  }, [loggedInUser]);

  if (loading || !loggedInUser) return <LoadingSection />;

  let profilePicObj = selectedImages ? (
    <ShowSelectedImage
      height={"130px"}
      width={"130px"}
      fileObject={selectedImages[0]}
    />
  ) : (
    <ProfilePicture user={loggedInUser} height="130px" width="130px" />
  );

  return (
    <Container>
      <Inputs>
        <ImageUploadSection>
          {profilePicObj}
          <ImageUploadButton onClick={chooseImage}>
            <AiOutlineUpload />
          </ImageUploadButton>
        </ImageUploadSection>

        <MaterialInput
          value={config.name}
          onChange={makeChange("name")}
          label={"Full Name"}
        />

        <MaterialInput
          value={config.username}
          onChange={makeChange("username")}
          label={"Username"}
        />

        <MaterialInput
          value={config.bio}
          multiline={true}
          rows={4}
          onChange={makeChange("bio")}
          label={"Describe yourself..."}
        />

        {loggedInUser.me.isPrimary ? (
          <>
            <CustomDateInput
              type="date"
              value={config.dateOfBirth}
              onChange={makeChange("dateOfBirth", true)}
              label={"Birthday"}
            />
            <CustomToggle
              name="Gender"
              options={[
                { label: "Male", value: "MALE" },
                { label: "Female", value: "FEMALE" },
                { label: "Other", value: "OTHER" },
              ]}
              value={config.gender}
              onChange={makeChange("gender", true)}
            />

            <SocialLinksEditor
              value={config.socialLinks}
              onChange={makeChange("socialLinks", true)}
            />
            <DropDownLocationInput
              onChange={makeChange("country", true)}
              type="COUNTRY"
              placeholder={""}
              value={config.country}
            />
          </>
        ) : null}
      </Inputs>
      <br /> <br />
      <FilledButton
        style={{ width: "200px", padding: "15px 0" }}
        icon={<AiOutlineCheck />}
        onClick={save}
        variant="filled"
      >
        Save
      </FilledButton>
      <br /> <br />
    </Container>
  );

  function makeChange(fieldName, isNotEvent) {
    return (e) => {
      if (isNotEvent) {
        let newItem = { ...config };
        newItem[fieldName] = e;
        setConfig(newItem);
        return true;
      }

      let newItem = { ...config };
      newItem[fieldName] = e.target.value;
      setConfig(newItem);
    };
  }

  async function chooseImage() {
    let selectedFiles = await selectFile({ onlyImage: true });

    if (selectedFiles) {
      if (selectedFiles.length) {
        showPopup({
          title: "Crop Image",
          component: (
            <ImageCropper2
              imageBlob={selectedFiles[0]}
              onCropDone={(newImage) => {
                window.goTo(-1)();
                setSelectedImages([newImage]);
              }}
            />
          ),
        });
      }
    }
  }

  async function uploadImage() {
    if (!selectedImages) return null;
    if (!selectedImages.length) return null;
    setLoading(true);
    // console.log("Uploading Image");
    let newFile = await compressAndUploadFile({
      selectedFile: selectedImages[0],
      folder: "profile-image",
    });
    setLoading(false);
    return newFile;
  }

  function isAlphaNumeric(str) {
    return /^[a-zA-Z0-9]+$/.test(str);
  }

  async function save() {
    // if (!config.bio) return popupAlert("Bio is missing");
    // if (!config.country) return popupAlert("Please add your country");

    // if (!config.dateOfBirth) return popupAlert("Date of birth is required.");
    if (config.username) {
      if (!isAlphaNumeric(config.username)) {
        return popupAlert(
          "The username can only contain alphabets and numbers"
        );
      }
    }

    // if (!userIsAdult(config.dateOfBirth))
    //   return popupAlert("Sorry! You need to 18 Years old to use CottonChat");

    try {
      let fileData = await uploadImage();

      let toSend = {
        changes: {},
      };

      toSend.changes.onboarding = "basicInfoAdded";
      toSend.changes.name = config.name;
      toSend.changes.bio = config.bio;
      toSend.changes.gender = config.gender;

      toSend.changes.dateOfBirth = config.dateOfBirth;

      toSend.changes.socialLinks = config.socialLinks;

      toSend.changes.username = config.username;

      toSend.changes.country = config.country;

      let newConfig = { ...config };

      if (fileData) {
        if (fileData.fileName) {
          toSend.changes.profileImage = {
            type: "S3_UPLOAD",
            data: fileData.fileName,
          };

          newConfig.profileImage = toSend.changes.profileImage;
        }
      }

      setLoading(true);

      await serverLine.patch("/profile", toSend);
      await refreshUser();

      setLoading(false);

      popupAlert("Saved!");
    } catch (e) {
      setLoading(false);
      popupAlert(e.message);
    }
  }
}
