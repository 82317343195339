import { useEffect } from "react";
import styled from "styled-components";
import updateRouteState from "./controllers/updateRouterState.js";
import PopupRoute from "./PopupRoute.jsx";
import getUrlQuery from "./controllers/getUrlQuery.js";
import getUrlSegments from "./controllers/getUrlSegments.js";

export default function RouterLogic({
  pathVsComp,
  currentRoute,
  nonPopupRoute,
  setCurrentRoute,
  setNonPopupRoute,
}) {
  useEffect(() => {
    let theFunc = () => {
      let newURl = window.location.pathname + window.location.search;

      updateRouteState(newURl, { setCurrentRoute, setNonPopupRoute });
    };

    window.addEventListener("popstate", theFunc);

    return () => {
      window.removeEventListener("popstate", theFunc);
    };
  }, []);

  let popupComp = null;
  let baseComp = findCompOfPath(currentRoute);
  let currentPageIsPopup = checkIsPopup(currentRoute);

  if (currentPageIsPopup && nonPopupRoute) {
    if (currentRoute !== nonPopupRoute) {
      baseComp = findCompOfPath(nonPopupRoute);
      popupComp = getPopupComp(currentRoute);
    }
  }

  return (
    <>
      {baseComp ? baseComp : null}
      {popupComp}
    </>
  );

  function findCompOfPath(pathToScan) {
    pathToScan = pathToScan.split("?")[0];
    if (pathToScan == "/") {
      let HomeComp = pathVsComp["/"];
      return (
        <HomeComp
          pathSegments={getUrlSegments(pathToScan)}
          queryParams={getUrlQuery(pathToScan)}
        />
      );
    }

    for (let aPath in pathVsComp) {
      if (aPath == "/") continue;
      let theComp = pathVsComp[aPath];
      let matchLength = aPath.length;
      let extracted = pathToScan.slice(0, matchLength);
      if (extracted === aPath) {
        let Comp = theComp;
        return (
          <Comp
            pathSegments={getUrlSegments(pathToScan)}
            queryParams={getUrlQuery(pathToScan)}
          />
        );
      }
    }

    if (pathVsComp["*"]) {
      let Comp = pathVsComp["*"];
      return (
        <Comp
          pathSegments={getUrlSegments(pathToScan)}
          queryParams={getUrlQuery(pathToScan)}
        />
      );
    }

    return "404";
  }

  function getPopupComp(pathName) {
    let isPopup = checkIsPopup(pathName);
    if (isPopup) {
      let element = findCompOfPath(pathName);

      return <PopupRoute element={element} />;
    } else {
      return false;
    }
  }
}

function checkIsPopup(path) {
  return path.indexOf("popup=") !== -1;
}
