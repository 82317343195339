import styled from "styled-components";
import LoggedOutHomePage from "./components/LoggedOutHomePage.js";
import InfoBox from "../../util/components/InfoBox.js";
import LoadingSection from "../../util/components/LoadingSection.js";
import MainFeed from "./components/feed/MainFeed.js";

import EnablePushNotificationCard from "../../services/firebase/pushNotification/components/EnablePushNotificationCard.js";
import useZekToolkit from "../../zekFrontendToolkit/useZekToolkit.js";

const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  align-items: center;
  padding: 10px 130px;
  padding-top: 20px;
  gap: 40px;

  @media (max-width: 1300px) {
    padding: 10px 60px;
  }

  @media (max-width: 900px) {
    padding: 0;
  }
`;

export default function HomePage() {
  const { loggedInUser, loggedInUserID } = useZekToolkit();

  if (!loggedInUserID) return <LoggedOutHomePage />;
  if (!loggedInUser) return <LoadingSection />;

  if (loggedInUser.me.isBanned)
    return (
      <InfoBox>
        You can't access this page because you have been banned.
      </InfoBox>
    );

  return (
    <Main>
      <EnablePushNotificationCard />

      <MainFeed />
    </Main>
  );
}
