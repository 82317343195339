import { useContext } from "react";
import { MoonLoader } from "react-spinners";
import styled from "styled-components";

import useZekToolkit from "../../../zekFrontendToolkit/useZekToolkit";

const ZoomAnimation = styled.div`
  //extra layer has been created so that the hover animation is not affected
  animation: centerScaleReveal 0.4s ease-out forwards;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--borderRadius);
`;

const Container = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: row;
  padding: 10px 18px;
  border-radius: var(--borderRadius);
  gap: 10px;
  font-size: 15px;
  cursor: pointer;

  /* animation: centerScaleReveal 0.4s ease-out forwards; */
  transition: all 0.15s ease-in-out !important;
  /* box-shadow: 0px 2px 20px 0 rgba(0, 0, 0, 0.08); */

  text-decoration: none;

  ${({ $backgroundColor, $hoverColor, $onHoverStyle }) => {
    return `
     
      background: ${$backgroundColor};
      &:hover{
        transform: scale(0.95);
        background: ${$hoverColor};
        ${$onHoverStyle ? $onHoverStyle : ""}
      };
    `;
  }}

  ${({ $highlight, $highlightColor }) => {
    if ($highlight)
      return `
          background: ${$highlightColor};
    `;
  }}

${({ $disabled }) => {
    if ($disabled)
      return `
          pointer-events: none;
    `;
  }}


  @media (max-width: 900px) {
    font-size: 14px;
    border-radius: 10px;
    padding: 8px 16px;
  }
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--inputText);
`;

const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--inputText);

  white-space: nowrap; /* Prevents line breaks */
  overflow: hidden; /* Hides the overflowing content */
  text-overflow: ellipsis; /* Adds ellipsis (…) to indicate overflow */
  /* width: 200px;   */
`;

export default function FilledButton({
  children,
  containerStyle,
  icon,
  onClick,
  highlight,
  style,
  mobileStyle,
  isLoading,
  colorVariation,
  href,
  onHoverStyle,
  textStyle,
  disabled,
  hoverColorOverride,
}) {
  const { isMobile } = useZekToolkit();

  if (!style) style = {};
  if (isMobile) style = { ...style, ...mobileStyle };
  if (isLoading) icon = <MoonLoader size={13} />;

  let backgroundColor = "var(--secondaryInput)";
  let highlightColor = "var(--primaryInput)";
  let hoverColor = "var(--primaryInput)";

  if (colorVariation == "SURFACE") {
    backgroundColor = "var(--secondarySurface)";
    highlightColor = "var(--primarySurface)";
    hoverColor = "var(--primarySurface)";
  }

  if (!onClick && href) {
    onClick = (e) => {
      e.preventDefault();
      window.goTo(href)();
      return false;
    };
  }

  return (
    <ZoomAnimation style={containerStyle}>
      <Container
        $disabled={disabled}
        $onHoverStyle={onHoverStyle}
        href={href}
        $hoverColor={hoverColorOverride ? hoverColorOverride : hoverColor}
        $backgroundColor={backgroundColor}
        $highlightColor={highlightColor}
        //
        onClick={onClick}
        $highlight={highlight}
        style={style}
      >
        {icon && <IconContainer>{icon}</IconContainer>}
        {children && (
          <TextContainer style={textStyle}>{children}</TextContainer>
        )}
      </Container>{" "}
    </ZoomAnimation>
  );
}
