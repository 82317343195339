import { useEffect, useState } from "react";
import Context from "../Context.js";

import AskBasicInfo from "../util/components/onboarding/AskBasicInfo.js";
import YouAreBanned from "../pages/manageReportedItemsPage/components/YouAreBanned.js";
import LoadingSection from "../util/components/LoadingSection.js";
import { App as CapacitorApp } from "@capacitor/app";
import initializeFirebase from "../services/firebase/pushNotification/initializeFirebase.js";
import useZekToolkit from "../zekFrontendToolkit/useZekToolkit.js";
import universalLocalStorage from "../zekFrontendToolkit/universalLocalStorage.js";
import serverLine from "../zekFrontendToolkit/network/serverLine.js";

CapacitorApp.addListener("backButton", ({ canGoBack }) => {
  if (!canGoBack) {
    CapacitorApp.exitApp();
  } else {
    window.history.back();
  }
});

window.nonReactLikeStatus = {};

function Boilerplate({ children }) {
  const { loggedInUserID, loggedInUser } = useZekToolkit();

  const [notificationData, setNotificationData] = useState({
    general: 0,
  });

  const [followingUserIDs, setFollowingUserIDs] = useState([]);
  const [allProfiles, setAllProfiles] = useState([]);

  //chat start
  const [chatMembershipAndNotifications, setChatMembershipAndNotifications] =
    useState(null);
  const [selectedChatGroupID, setSelectedChatGroupID] = useState(null);
  const [typingData, setTypingData] = useState({});
  const [chatGroupsAreLoading, setChatGroupsAreLoading] = useState(true);
  const [selectedChatGroupIsLoading, setSelectedChatGroupIsLoading] =
    useState(false);
  const [chatGroupsType, setChatGroupsType] = useState("GENERAL");
  const [selectedChatGroupData, setSelectedChatGroupData] = useState(null);
  const [mutedChatGroups, setMutedChatGroups] = useState([]);
  const [chatGroups, setChatGroups] = useState([]);
  const [isConnected, setIsConnected] = useState(false);
  const [friendsLastSeenAt, setFriendsLastSeenAt] = useState(null);
  const [replyingTo, setReplyingTo] = useState(null);
  const [oppositeMembers, setOppositeMembers] = useState([]);
  const [pushNotificationDecision, setPushNotificationDecision] =
    useState(null);

  useEffect(() => {
    loadOnce();
  }, [loggedInUserID]);

  useEffect(() => {
    if (loggedInUser) {
      setAllProfiles(loggedInUser.allProfiles);
      setFollowingUserIDs(loggedInUser.followingUserIDs);
    } else {
      setAllProfiles([]);
      setFollowingUserIDs([]);
    }
  }, [loggedInUser]);

  let globalChatSpace = {
    loggedInUser,
    loggedInUserID,
    //
    replyingTo,
    setReplyingTo,
    //
    chatGroupsType,
    setChatGroupsType,
    //
    mutedChatGroups,
    setMutedChatGroups,
    //
    oppositeMembers,
    setOppositeMembers,
    //
    chatMembershipAndNotifications,
    setChatMembershipAndNotifications,
    //
    selectedChatGroupID,
    setSelectedChatGroupID,
    //
    typingData,
    setTypingData,
    //
    chatGroupsAreLoading,
    setChatGroupsAreLoading,
    //
    selectedChatGroupIsLoading,
    setSelectedChatGroupIsLoading,
    //
    chatGroups,
    setChatGroups,
    //
    selectedChatGroupData,
    setSelectedChatGroupData,
    //
    isConnected,
    setIsConnected,
    //
    friendsLastSeenAt,
    setFriendsLastSeenAt,
  };

  window.globalChatSpace = globalChatSpace;

  useEffect(() => {
    initializeFirebase();
  }, []);

  useEffect(() => {
    if (window.notifInterval) {
      window.clearInterval(window.notifInterval);
    }

    if (loggedInUserID) {
      updateNotificationData();

      window.notifInterval = window.setInterval(() => {
        updateNotificationData();
      }, 30 * 1000);
    }
  }, [loggedInUserID]);

  if (loggedInUserID) {
    if (!loggedInUser) {
      children = <LoadingSection />;
    } else {
      if (loggedInUser.me.isBanned) children = <YouAreBanned />;
      if (!loggedInUser.me.onboarding) children = <AskBasicInfo />;
      if (loggedInUser.me.onboarding)
        if (!loggedInUser.me.onboarding.basicInfoAdded)
          children = <AskBasicInfo />;
    }
  }

  let sharedState = {
    followingUserIDs,
    setFollowingUserIDs,
    allProfiles,
    setAllProfiles,
    //
    pushNotificationDecision,
    setPushNotificationDecision,
    updatePushNotificationDecision,

    notificationData,
    updateNotificationData,

    //Chat start
    globalChatSpace,
  };

  window.sharedState = sharedState;

  return <Context.Provider value={sharedState}>{children}</Context.Provider>;

  async function loadOnce() {
    loadNotificationDecision();
  }

  async function updatePushNotificationDecision(value) {
    await universalLocalStorage.setItem(
      "cottonchat-push-notification-decision",
      value
    );
    setPushNotificationDecision(value);
  }

  async function loadNotificationDecision() {
    let pushNotificationDec = await universalLocalStorage.getItem(
      "cottonchat-push-notification-decision"
    );
    setPushNotificationDecision(pushNotificationDec);
  }

  function updateNotificationData() {
    // console.log(loggedInUser);
    serverLine.get("/notification-count").then(setNotificationData);
  }
}

export default Boilerplate;
