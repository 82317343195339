import serverLine from "../../../../zekFrontendToolkit/network/serverLine";
import loadChatGroups from "./loadChatGroups";
import loadSelectedChatGroup from "./loadSelectedChatGroup";

export default async function muteSelectedChatGroup() {
  let {
    selectedChatGroupData,

    setSelectedChatGroupIsLoading,
  } = window.globalChatSpace;

  let theGroup = selectedChatGroupData;
  if (!selectedChatGroupData) return null;

  let myMembership = getMyMembershipData();
  if (!myMembership) return;

  setSelectedChatGroupIsLoading(null);

  if (myMembership.isMuted) {
    await serverLine.post("/mute-chat-group", {
      chatGroupID: theGroup.group._id,
      action: "UNMUTE",
    });

    window.popupAlert("Chat Group Has Been Unmuted");
  } else {
    await serverLine.post("/mute-chat-group", {
      chatGroupID: theGroup.group._id,
      action: "MUTE",
    });

    window.popupAlert("Chat Group Has Been Muted");
  }

  loadSelectedChatGroup(theGroup.group._id);
  loadChatGroups();
}

function getMyMembershipData() {
  let { selectedChatGroupData, loggedInUserID } = window.globalChatSpace;

  for (let item of selectedChatGroupData.membersMembershipData) {
    if (item.userID == loggedInUserID) return item;
  }

  return null;
}
