import { styled } from "styled-components";

import { useEffect, useState } from "react";

import { MdOutlineEdit } from "react-icons/md";

import getReportOptions from "../../util/controllers/getReportOptions";
import DropDownInput from "../../util/components/inputs/DropDownInput";
import FeedItemDiscussion from "../../util/components/feedItem/FeedItemDiscussion";
import InfoBox from "../../util/components/InfoBox";
import UserBox from "../profilePage/UserBox";
import MaterialInput from "../../util/components/MaterialInput";
import BeautifulTitle from "../../util/components/BeautifulTitle";
import LoadingSection from "../../util/components/LoadingSection";
import FilledButton from "../../util/components/buttons/FilledButton";
import FeedItemConclusion from "../../util/components/feedItem/FeedItemConclusion";

import useZekToolkit from "../../zekFrontendToolkit/useZekToolkit";
import serverLine from "../../zekFrontendToolkit/network/serverLine";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* gap: 50px; */
  /* overflow-y: scroll; */
  width: 100%;
  height: 100%;
`;

const Main = styled.div`
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 50px;
  width: 38vw;
  align-items: center;

  @media (max-width: 900px) {
    width: 100%;
    padding-top: 20px;
  }
`;

const Inputs = styled.div`
  display: flex;
  background-color: var(--primarySurface);
  padding: 20px;
  border-radius: 10px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
`;

export default function CreateReport({ urlParams }) {
  const { popupAlert } = useZekToolkit();
  const [loading, setLoading] = useState(true);
  const [itemData, setItemData] = useState(null);
  const [reason, setReason] = useState("");
  const [description, setDescription] = useState("");
  const [reportSent, setReportSent] = useState(false);

  let subjectID = urlParams["subjectID"];
  let subjectType = urlParams["subjectType"];

  useEffect(() => {
    if (subjectType === "content") {
      serverLine.get("/content/?contentID=" + subjectID).then((newData) => {
        setItemData(newData);
        setLoading(false);
      });
    } else if (subjectType === "conclusion") {
      serverLine
        .get("/conclusion/?conclusionID=" + subjectID)
        .then((newData) => {
          setItemData(newData);
          setLoading(false);
        });
    } else if (subjectType === "profile") {
      serverLine.get("/profile/?userID=" + subjectID).then((newData) => {
        if (newData.user) {
          setItemData(newData.user);
        }

        setLoading(false);
      });
    }
  }, [subjectID, subjectType]);

  if (!itemData || loading) return <LoadingSection />;

  if (reportSent)
    return (
      <Container>
        <Main>
          <BeautifulTitle>Your report has been sent</BeautifulTitle>

          <InfoBox>
            We will review it and take action as soon as possible. Thankyou for
            reporting it. We are extremely sorry for the offense caused by the
            author of this post.
          </InfoBox>
        </Main>
      </Container>
    );

  let theItem = null;

  if (subjectType == "content")
    theItem = <FeedItemDiscussion colorVariation={"SURFACE"} item={itemData} />;
  if (subjectType == "conclusion")
    theItem = <FeedItemConclusion colorVariation={"SURFACE"} item={itemData} />;
  if (subjectType == "profile") theItem = <UserBox item={itemData} />;

  return (
    <Container>
      <Main>
        <BeautifulTitle icon={<MdOutlineEdit />}>Create Report</BeautifulTitle>
        {theItem}
        <Inputs>
          <DropDownInput
            optionsViewType="LIST"
            value={reason}
            options={getReportOptions()}
            placeholder="Select Reason"
            label="Reason"
            onChange={setReason}
          />

          <MaterialInput
            value={description}
            multiline={true}
            rows={3}
            label="Type reason in detail"
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          />

          <FilledButton
            style={{ borderRadius: "10px", width: "200px" }}
            variant="filled"
            onClick={sendReport}
          >
            Send Report
          </FilledButton>
        </Inputs>
        <br /> <br /> <br /> <br /> <br /> <br />
      </Main>
    </Container>
  );

  async function sendReport() {
    if (!reason || !description)
      return popupAlert("Please fill all the required fields");

    setLoading(true);

    try {
      await serverLine.post("/report", {
        subjectID: subjectID,
        subjectType,
        reason,
        description,
      });

      setReportSent(true);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      popupAlert(e.message);
    }
  }
}
