import { useContext } from "react";
import ZekToolkitContext from "./ZekToolkitContext";
import navigate from "./router/controllers/navigate";
import generateSetPopupFunc from "./dynamicPopup/generateSetPopupFunc";
import updateColorMode from "./colors/updateColorMode";
import universalLocalStorage from "./universalLocalStorage";

export default function useZekToolkit() {
  const {
    currentRoute,
    setCurrentRoute,
    setNonPopupRoute,
    isMobile,
    screenWidth,
    setDynamicPopupData,
    popupAlert,
    namespace,
    setColorMode,
    defaultColorMode,
    lightTheme,
    colorMode,
    darkTheme,
    refreshUser,
    loggedInUser,
    loggedInUserID,
    logout,
    goTo,
    globalState,
    setGlobalState,
    doGoogleLogin,
    themeOverride,
    setThemeOverride,
  } = useContext(ZekToolkitContext);

  window.goTo = goTo;

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  let showPopup = generateSetPopupFunc(setDynamicPopupData);

  let changeColorMode = (colorMode) => {
    updateColorMode({
      newColorMode: colorMode,
      setColorMode,
      namespace,
      lightTheme,
      darkTheme,
    });
  };

  let switchColorMode = async () => {
    let colorMode = await universalLocalStorage.getItem(
      namespace + "-colorMode"
    );
    if (!colorMode) colorMode = defaultColorMode;
    changeColorMode(colorMode == "DARK" ? "LIGHT" : "DARK");
  };

  let features = {
    setThemeOverride,
    timeZone,
    doGoogleLogin,
    namespace,
    goTo,
    currentRoute,
    isMobile,
    screenWidth,
    showPopup,
    popupAlert,
    colorMode,
    changeColorMode,
    switchColorMode,
    refreshUser,
    loggedInUserID,
    loggedInUser,
    logout,
    globalState,
    setGlobalState,
    themeOverride,
  };

  window.zekToolkitFeatures = features;

  return features;
}
