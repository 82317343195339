import serverLine from "../network/serverLine";
import storeAuthCred from "./storeAuthCred";

export default function sendGoogleAccessToken({
  accessToken,
  namespace,
  refreshUser,
}) {
  serverLine
    .post("/google-auth-access-token", { accessToken })
    .then(done)
    .catch(({ message }) => {
      console.warn(message);
      if (typeof message == "object") message = JSON.stringify(message);
      window.popupAlert(message);
    });

  async function done(data) {
    if (data) {
      await storeAuthCred({ ...data, namespace });
      // window.location = "/";
      refreshUser();
      // window.location.reload();
    }
  }
}
