import { useEffect, useState } from "react";
import ZekToolkit from "./ZekToolkitContext";
import CustomRouter from "./router/RouterLogic";
import DynamicPopup from "./dynamicPopup/DynamicPopup.jsx";
import { createTheme, ThemeProvider } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ColorsLoader from "./colors/ColorsLoader";
import { useGoogleLogin } from "@react-oauth/google";
import LoadingSpinner from "./ui/LoadingSpinner";
import handleGoogleAuthRes from "./auth/handleGoogleAuthRes";
import universalLocalStorage from "./universalLocalStorage";
import serverLine from "./network/serverLine.js";
import navigate from "./router/controllers/navigate.js";
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import { Capacitor } from "@capacitor/core";

export default function ZekToolkitProvider(config) {
  let {
    routes,
    localServerURL,
    productionServerURL,
    forceProductionMode,
    muiTheme,
    namespace,
    lightTheme,
    darkTheme,
    defaultColorMode,
    googleAuthClientID,
    RouteEncapsulation,
  } = config;
  const [dynamicPopupData, setDynamicPopupData] = useState(null);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  let currentPath = window.location.pathname + window.location.search;
  const [currentRoute, setCurrentRoute] = useState(currentPath);
  const [nonPopupRoute, setNonPopupRoute] = useState(currentPath);
  const [colorMode, setColorMode] = useState(null);
  const [doingLogin, setDoingLogin] = useState(null);
  const [loggedInUser, setLoggedInUser] = useState(false);
  const [loggedInUserID, setLoggedInUserID] = useState(false);
  const [loadingUser, setLoadingUser] = useState(true);
  const [globalState, setGlobalState] = useState({});
  const [themeOverride, setThemeOverride] = useState(null);

  useEffect(() => {
    //For google auth in capacitor to work we need the following
    GoogleAuth.initialize({
      clientId: googleAuthClientID,
      scopes: ["profile", "email"],
      grantOfflineAccess: true,
    });
  }, []);

  const doGoogleLoginForWeb = useGoogleLogin({
    onSuccess: handleGoogleAuthRes({
      setLoading: setDoingLogin,
      namespace,
      refreshUser,
    }),
  });

  let goTo = (href, options) =>
    navigate(href, options, { setCurrentRoute, setNonPopupRoute });

  console.log("dynamicPopupData", dynamicPopupData);
  window.zekToolkitFunc = { logout };
  window.zekToolkitConfig = config;

  window.serverlineConfig = {
    namespace: namespace,
    localServerURL,
    productionServerURL,
    forceProductionMode,
  };

  let isMobile = screenWidth < 900;
  const themeMUI = createTheme(muiTheme);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    refreshUser();
  }, []);

  const popupAlert = (message) => {
    if (typeof message == "object") {
      message = JSON.stringify(message);
    }

    toast.info(message, {
      icon: false,
      style: { backgroundColor: "#111", color: "var(--color)" },
      progressStyle: { backgroundColor: "var(--accentColor)" },
    });
  };
  window.popupAlert = popupAlert;

  let content = (
    <CustomRouter
      pathVsComp={routes}
      currentRoute={currentRoute}
      nonPopupRoute={nonPopupRoute}
      setCurrentRoute={setCurrentRoute}
      setNonPopupRoute={setNonPopupRoute}
    />
  );

  if (doingLogin || loadingUser) content = <LoadingSpinner />;

  return (
    <ThemeProvider theme={themeMUI}>
      <ZekToolkit.Provider
        value={{
          isMobile,
          dynamicPopupData,
          setDynamicPopupData,
          screenWidth,
          localServerURL,
          productionServerURL,
          forceProductionMode,
          currentRoute,
          setCurrentRoute,
          nonPopupRoute,
          setNonPopupRoute,
          popupAlert,
          namespace,
          colorMode,
          setColorMode,
          lightTheme,
          darkTheme,
          doGoogleLogin,
          refreshUser,
          loggedInUser,
          loggedInUserID,
          logout,
          defaultColorMode,
          globalState,
          setGlobalState,
          goTo,
          setThemeOverride,
        }}
      >
        <RouteEncapsulation>{content}</RouteEncapsulation>
        <ColorsLoader
          themeOverride={themeOverride}
          lightTheme={lightTheme}
          darkTheme={darkTheme}
          setColorMode={setColorMode}
          namespace={namespace}
          defaultColorMode={defaultColorMode}
        />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
        <DynamicPopup
          data={dynamicPopupData}
          setData={setDynamicPopupData}
          currentRoute={currentRoute}
        />
      </ZekToolkit.Provider>
    </ThemeProvider>
  );

  async function doGoogleLogin(setLoading) {
    const platform = Capacitor.getPlatform();

    try {
      if (platform === "android" || platform === "android") {
        if (setLoading) setLoading(true);
        await doGoogleLoginForApp();
        if (setLoading) setLoading(false);
      } else if (platform === "web") {
        //we don't do loading part here because of api limitation and it will look bad
        await doGoogleLoginForWeb();
      } else {
        console.log("Unknown platform");
      }
    } catch (e) {
      popupAlert(e.message);
      if (setLoading) setLoading(false);
    }
  }

  async function doGoogleLoginForApp() {
    const response = await GoogleAuth.signIn();
    console.log(response);
    let refreshData = await GoogleAuth.refresh();

    handleGoogleAuthRes({
      setLoading: setDoingLogin,
      namespace,
      refreshUser,
    })({ access_token: refreshData.accessToken });
  }

  async function refreshUser() {
    let userID = await universalLocalStorage.getItem(`${namespace}-userID`);

    if (userID) {
      setLoggedInUserID(userID);
      setLoadingUser(true);
      let userData = await serverLine.get("/logged-in-user");
      setLoggedInUser(userData);
      setLoadingUser(false);
    } else {
      setLoadingUser(false);
      setLoggedInUser(null);
      setLoggedInUserID(null);
    }
  }

  async function logout() {
    await universalLocalStorage.clear();
    await refreshUser();
    goTo("/")();
  }
}
