import { styled } from "styled-components";
import { AiOutlineDown } from "react-icons/ai";
import PopupSelect from "../PopupSelect.js";
import FilledButton from "../buttons/FilledButton.js";
import useZekToolkit from "../../../zekFrontendToolkit/useZekToolkit.js";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
  width: 100%;
  background: var(--secondaryInput);
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-radius: 10px;
  gap: 15px;
`;

const Label = styled.div`
  width: 100%;
  opacity: 1;
  font-size: 13px;
  font-weight: 400;
  text-transform: capitalize;
`;

export default function DropDownInput({
  value,
  onChange,
  options,
  label,
  style,
  popupComponent,
  optionsViewType,
  colorVariation,
  disableSearch,
  hideLabel,
}) {
  const { showPopup } = useZekToolkit();

  let valToShow = getLabelFromValue({ value, options });

  let toShow = valToShow ? valToShow : label;

  return (
    <FilledButton
      colorVariation={colorVariation}
      icon={<AiOutlineDown />}
      style={{ flexDirection: "row-reverse", ...style }}
      onClick={() => {
        showPopup({
          title: label,
          component: popupComponent ? (
            popupComponent
          ) : (
            <PopupSelect
              disableSearch={disableSearch}
              optionsViewType={optionsViewType}
              value={value}
              options={options}
              onChange={onChange}
            />
          ),
        });
      }}
    >
      {hideLabel ? null : toShow}
    </FilledButton>
  );
}

function getLabelFromValue({ value, options }) {
  if (!options) return value; // In case of location input

  for (let item of options) {
    if (item.value == value) return item.label;
  }

  return false;
}
