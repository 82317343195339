import { useContext } from "react";
import Context from "../../../../../Context";
import MyMessage from "./MyMessage";
import TheirMessage from "./TheirMessage";
import { styled } from "styled-components";

import ShowSelectedImage from "../../../../../util/components/ShowSelectedImage";
import getImageURL from "../../../../../util/controllers/getImageURL";
import InfoMessage from "./InfoMessage";
import useZekToolkit from "../../../../../zekFrontendToolkit/useZekToolkit";

const Content = styled.div`
  margin: 0;
  /* white-space: pre; */
`;

const Image = styled.img`
  width: 300px;
  min-height: 200px;
  object-fit: cover;
  // If min-height is not set, chat scroll to bottom won't work because image takes time to load
  border-radius: 10px;

  @media (max-width: 900px) {
    width: 200px;
  }
`;

export default function MessageBox({ message, group }) {
  const { loggedInUserID } = useZekToolkit();

  let content = null;

  if (message.type !== "GENERAL") return <InfoMessage message={message} />;

  if (message.message.text) {
    content = <Content>{message.message.text}</Content>;
  }

  if (message.message.image) {
    let theImage = message.message.image;

    if (theImage.type == "LOCAL_IMAGE") {
      // console.log(window.localImages[theImage.data], "IMAGE_BLOBBBBB");

      content = (
        <ShowSelectedImage
          width="300px"
          height="auto"
          borderRadius="10px"
          fileObject={window.localImages[theImage.data]}
        />
      );
    } else {
      content = <Image src={getImageURL(message.message.image)} />;
    }
  }

  if (message.authorUserID == loggedInUserID)
    return <MyMessage message={message} group={group} content={content} />;

  return <TheirMessage message={message} content={content} group={group} />;
}
