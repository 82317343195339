import { useEffect, useState } from "react";
import styled from "styled-components";
import Context from "../../Context";

import getImageURL from "../../util/controllers/getImageURL";
import LoadingSection from "../../util/components/LoadingSection";

import FilledButton from "../../util/components/buttons/FilledButton";
import useZekToolkit from "../../zekFrontendToolkit/useZekToolkit";
import serverLine from "../../zekFrontendToolkit/network/serverLine";

const Container = styled.div`
  padding: 25px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  padding-bottom: 50px;
`;

const AuthorData = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const AuthorImage = styled.img`
  height: 150px;
  width: 150px;
  object-fit: cover;
  border-radius: 200px;
`;

const AuthorName = styled.h3`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export default function BlockUnblockInterface({
  receiverUserID,
  senderUserID,
  receiverUserData,
  callback,
}) {
  const { popupAlert, goTo } = useZekToolkit(Context);
  const [status, setStatus] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    serverLine
      .get(
        `/block-status/?senderUserID=${senderUserID}&receiverUserID=${receiverUserID}`
      )
      .then((data) => {
        setStatus(data);
        setLoading(false);
      });
  }, []);

  let authorData = (
    <AuthorData>
      <AuthorImage src={getImageURL(receiverUserData.profileImage, true)} />
      <AuthorName> {receiverUserData.name} </AuthorName>
    </AuthorData>
  );

  if (loading)
    return (
      <Container>
        <LoadingSection />
      </Container>
    );

  return (
    <Container>
      {authorData}
      {status ? (
        <FilledButton onClick={takeAction(true)}>Unblock</FilledButton>
      ) : (
        <FilledButton onClick={takeAction()}>Block</FilledButton>
      )}
    </Container>
  );

  function takeAction(unblock) {
    return async () => {
      setLoading(true);
      await serverLine.post("/block", {
        senderUserID,
        receiverUserID,
        action: unblock ? "UNBLOCK" : "BAN",
      });
      if (callback) callback();

      goTo(-1)();
      setStatus(!status);
      popupAlert("Done");
    };
  }
}
