import { useState } from "react";
import { styled } from "styled-components";
import MaterialInput from "./MaterialInput.js";
import { AiOutlineStar } from "react-icons/ai";
import useZekToolkit from "../../zekFrontendToolkit/useZekToolkit.js";

const Container = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

const List = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  /* flex-direction: column; */

  /* display: grid; */
  /* grid-template-columns: 1fr 1fr 1fr; */
`;
const Item = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  min-width: 150px;
  max-width: 200px;
  justify-content: center;
  align-items: center;
  padding: 15px 25px;
  cursor: pointer;
  background: var(--secondaryInput);
  font-weight: 500;
  border-radius: 10px;
  animation: centerScaleReveal 0.4s ease-out forwards;

  &:hover {
    background: var(--primaryInput);
  }

  ${({ isActive }) => {
    if (isActive)
      return `
           background: var(--primaryInput);
    `;
  }}
`;

const ItemText = styled.div``;

const ImportantSymbol = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function PopupSelect({
  value,
  options,
  onChange,
  optionsViewType,
  disableSearch,
}) {
  const { goTo } = useZekToolkit();
  const [query, setQuery] = useState("");

  let listStyle = {};
  let itemStyle = {};

  if (optionsViewType === "LIST") {
    listStyle = { display: "flex", flexDirection: "column", gap: "10px" };
    itemStyle = { width: "100%", maxWidth: "unset" };
  }

  let searchInput = (
    <MaterialInput
      value={query}
      autoFocus={true}
      onChange={({ target }) => {
        setQuery(target.value);
      }}
      label="Type here to search"
    />
  );

  if (disableSearch) searchInput = null;

  return (
    <Container>
      {searchInput}
      <List style={listStyle}>{renderList()}</List>
    </Container>
  );

  function renderList() {
    let items = [];

    for (let item of options) {
      if (query) {
        let toMatch = item.label.toString().toLowerCase();
        if (toMatch.indexOf(query.toLowerCase()) === -1) {
          continue;
        }
      }
      items.push(
        <Item
          style={itemStyle}
          key={item.value}
          isActive={value == item.value}
          onClick={() => {
            window.goTo(-1);
            onChange(item.value);
          }}
        >
          <ItemText>{item.label}</ItemText>

          {item.isImportant ? (
            <ImportantSymbol>
              <AiOutlineStar />
            </ImportantSymbol>
          ) : null}
        </Item>
      );
    }

    return items;
  }
}
