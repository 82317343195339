import getObjectID from "../../../../util/controllers/getObjectID";

import serverLine from "../../../../zekFrontendToolkit/network/serverLine";
import sendLeftChatGroup from "../socket/send/sendLeftChatGroup";
import sendMessage from "../socket/send/sendMessage";
import loadChatGroups from "./loadChatGroups";

export default async function leaveChatGroup(chatGroupID) {
  let { setSelectedChatGroupIsLoading } = window.globalChatSpace;
  setSelectedChatGroupIsLoading(true);

  //Send message to all the members that I am leaving the group
  await broadcastIamLeavingTheChat();
  // This needs to be done first, otherwise members
  //won't be able to receive the member left message

  await serverLine.post(`/leave-chat-group/`, { chatGroupID });

  //This needs to happen right after leave-chat-group so that fresh membership data can be generated.
  sendLeftChatGroup(chatGroupID);

  //Redirect to home
  window.goTo("/chat")();
  loadChatGroups();
  window.popupAlert("Chat Group Left!");
  setSelectedChatGroupIsLoading(false);
}

function broadcastIamLeavingTheChat() {
  return new Promise((resolve) => {
    let { selectedChatGroupData } = window.globalChatSpace;

    let messageID = getObjectID();

    let messageDoc = { _id: messageID, type: "MEMBER_LEFT" };

    sendMessage({
      messageDoc,
      chatGroup: selectedChatGroupData.group,
      onSuccess: resolve,
    });
  });
}
