import MobileChatMainPanel from "./MobileChatMainPanel";
import DesktopChatMainPanel from "./DesktopChatMainPanel";
import useZekToolkit from "../../../../zekFrontendToolkit/useZekToolkit";

export default function ChatMainPanel() {
  const { isMobile } = useZekToolkit();

  let { selectedChatGroupID } = window.globalChatSpace;

  if (!selectedChatGroupID) return null;

  if (isMobile) return <MobileChatMainPanel />;

  return <DesktopChatMainPanel />;
}
